* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html {
    font-size: 16px;
}
@media (max-width: 900px) {
    html {
        font-size: 15px;
    }
}
@media (max-width: 400px) {
    html {
        font-size: 13px;
    }
}

body {
    width: 100%;
    min-height: 100vh;
}

